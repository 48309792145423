import type { DataTableFilterMeta, DataTablePageEvent } from 'primevue/datatable'
import type {DataViewPageEvent} from 'primevue/dataview'

export const getFiltersValue = (filters: DataTableFilterMeta) => {
  const newFilters: Record<string, Date|Date[]|string|string[]|undefined> = {}

  Object.keys(filters).forEach((key: string) => {
    const filter = filters[key]

    if (typeof filter === 'string') return
    if (!('value' in filter)) return

    let value = filter.value

    if (value && Object.hasOwn(value, 'id')) value = value.id

    newFilters[key] = value
  })

  return newFilters
}

export const getPagination =  (event: DataTablePageEvent | DataViewPageEvent): PaginationInput => {
  return {
    page: event.page + 1,
    per_page: event.rows
  }
}
